/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
} from "../../../utils/methods";
import { ImageUrl, imageErrorMessage } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  UploadPhotoContainer,
  RemoveImage,
  InputImage,
  ButtonContainer,
  Button,
  QueAndCard,
  StyledDateTime,
  // CustomRadioButton,
  // StyledLabelV2,
  // EditorContainer,
  // Heading,
  // Locationwrapper,
} from "./style";
import { Main } from "../../AdminTeamCreation/styles";
// import Editor from '../../CKEditor';
import StepsHeading from "../../CreateEvent/stepNames";
import Waiting from "../../Waiting";
import HoverButton from "../../common/HoverButton";
import {
  addQueIcon,
  calendarIcon,
  closeIcon,
  likertScaleIcon,
  mcqIcon,
  opinionScaleIcon,
  reorderIcon,
  shortDescIcon,
  yesnoIcon,
} from "../../../utils/icons";
import {
  CreateNewSurvey,
  CreateSurveyAnswer,
  CreateSurveyQuestion,
  DeleteSurveyQuestionAnswers,
  EditSurveyLibrary,
  GetSurvey,
  GetSurveyCategories,
  GetSurveyLibrary,
  GetSurveyLibraryQuestionAnswers,
  GetSurveyQuestionAnswers,
  GetSurveyQuestionTypes,
  SaveSurveyAsTemplate,
  UpdateSurvey,
} from "../../../redux/constants/apiConstants";
import { BASE_URL } from "../../../utils/config";
import CommonTextArea from "../../common/CommonTextArea/CommonTextArea";
import DropDown from "../../common/CommonDropDown/DropDown";
import { Border } from "../../EventDetails/styles";
import { ToggleButton } from "../../Recognition/AddCoreValues/styles";
import { withRouter } from "react-router-dom";
import CreateSurveyPopUp from "./CreateSurveyPopUp";
import RadioButton from "../../CustomRadioButton";
import { t } from "i18next";
import { InputContainerV2 } from "../../CreateEvent/style";
import { DateIconContainer } from "../../CreateEvent/styles";
import moment from "moment";
import Datetime from "react-datetime";
import QuestionReorderPopUp from "./QuestionReorderPopUp";

const surveypoints = [
  { value: 0, text: "0 Point" },
  { value: 25, text: "25 Points" },
  { value: 50, text: "50 Points" },
  { value: 100, text: "100 Points" },
  { value: 150, text: "150 Points" },
  { value: 200, text: "200 Points" },
  { value: 250, text: "250 Points" },
  { value: 300, text: "300 Points" },
  { value: 350, text: "350 Points" },
  { value: 400, text: "400 Points" },
  { value: 450, text: "450 Points" },
  { value: 500, text: "500 Points" },
  { value: 600, text: "600 Points" },
  { value: 650, text: "650 Points" },
  { value: 700, text: "700 Points" },
  { value: 750, text: "750 Points" },
  { value: 800, text: "800 Points" },
  { value: 850, text: "850 Points" },
  { value: 900, text: "900 Points" },
  { value: 950, text: "950 Points" },
  { value: 1000, text: "1000 Points" },
];
const opinionpoints = [
  { value: 0, text: "0pt" },
  { value: 10, text: "10pts" },
];

const likeartpoints = [
  { value: 1, text: "1pt" },
  { value: 5, text: "5pts" },
];
// const surveypoints =[{value:5, text:"5 Points"}, {value:10, text:"10 Points"},{value:15, text:"15 Points"}];

const likertScaleValues = [
  { value: "Strongly Disagree", points: 1 },
  { value: "Disagree", points: 2 },
  { value: "Neutral", points: 3 },
  { value: "Agree", points: 4 },
  { value: "Strongly Agree", points: 5 },
];

const opinionScaleValues = [
  { value: "", points: 0 },
  { value: "", points: 1 },
  { value: "", points: 2 },
  { value: "", points: 3 },
  { value: "", points: 4 },
  { value: "", points: 5 },
  { value: "", points: 6 },
  { value: "", points: 7 },
  { value: "", points: 8 },
  { value: "", points: 9 },
  { value: "", points: 10 },
];

class CreateSurvey extends Component {
  constructor(props) {
    super(props);
    this.targetDivRef = React.createRef();
    this.state = {
      imgSrc: "",
      title: "",
      description: "",
      imageName: "",
      exerciseFocusAreas: [],
      addLimitCheckbox: false,
      workOutVideoLink: "",
      step: 1,
      selectedFocusAreaValue: "",
      selectedFocusAreaCheckBox: 1,
      buttonClickScrollTop: false,
      timeout: false,
      allExerciseFocusAreas: [],
      exerciseTypes: [],
      isVideoUrl: false,
      isVideoUrlMsg: "",
      isEdit: false,
      loading: false,
      imageUpdate: 0,
      surveyCategories: [],
      selectedSurveyCategory: { id: 0, name: "Select type of survey" },
      selectedSurveyPoint: { value: 0, text: "0 Point" },
      companyId: props.companyInfo["id"],
      createdSurveyDetails: {},
      surveyQuestionTypes: [],
      questionAnswers: [
        {
          questionType: {},
          question: "",
          questionId: "",
          isFollowUp: false,
          followUpQue: "",
          isMcqWeightage: false,
          multipleMcqResponses: false,
          mcqDropDownOptions: [
            { value: 1, text: "1pt" },
            { value: 1, text: "1pt" },
          ],
        },
      ],
      isLaunchNow: false,
      isSchedule: false,
      openDateTimePicker: "",
      date: moment(),
      endDate: "",
      showDateTimeInput: "",
      endType: "date",
      mcqpoints: [
        { value: 1, text: "1pt" },
        { value: 1, text: "1pt" },
      ],
      showCreateSurveyPopUp: false,
      showQueReorderPopUp: false,
      disable: true,
      participantLimit: "",
      isActiveOrUpcoming: false,
      messageToEmployeeCharacterCount: 0
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const editData = this.props.history.location.state;

    if (editData && editData.surveyId && !this.state.createdSurveyDetails.id) {
      this.getSurveyDetails(editData.surveyId);
    }

    this.fetchSurveyCategories();
    this.fetchQuestionTypes(editData);

    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClick);
  }

  componentDidUpdate() {
    if (this.state.buttonClickScrollTop) {
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.setState({ buttonClickScrollTop: false });
      }, 2000);
    }
  }

  getSurveyDetails = async (surveyId) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    const { type } = this.props.history.location.state;
    await fetch(
      BASE_URL +
        (type === "editsurveylibrary" ? GetSurveyLibrary : GetSurvey) +
        "?id=" +
        surveyId,
      {
        method: "GET",
        headers: { AUTHTOKEN: AuthToken },
      }
    )
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (this._isMounted) {
              const { title, image, description, category, config } =
                data.survey[0];
              this.setState({
                createdSurveyDetails: data.survey[0],
                isEdit: true,
                title: title,
                imgSrc: ImageUrl + "/" + image,
                description: description,
                selectedSurveyCategory: category,
                isActiveOrUpcoming:
                  (data.survey[0].status === "upcoming" ||
                    data.survey[0].status === "active" ||
                    data.survey[0].status === "completed" ||
                    data.survey[0].status === "archived") &&
                  type != "editsurveylibrary" &&
                  true,
              });

              if (
                data.survey[0].status === "active" ||
                data.survey[0].status === "upcoming"
              ) {
                this.setState({
                  date: moment(data.survey[0].config.launch_date),
                  endDate: moment(data.survey[0].config.end_date),
                  endType: data.survey[0].config.end_type,
                  participantLimit: data.survey[0].config.participant_limit,
                });
                if (data.survey[0].status === "active") {
                  this.setState({ isLaunchNow: true, isSchedule: false });
                } else if (data.survey[0].status === "upcoming") {
                  this.setState({ isLaunchNow: false, isSchedule: true });
                }
              }
              for (let i = 0; i < surveypoints.length; i++) {
                if (surveypoints[i].value === config.points) {
                  this.setState({ selectedSurveyPoint: surveypoints[i] });
                }
              }
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: moment(e._d).format("MM/DD/YYYY"),
        time: moment(e._d).format("hh:mm a"),

        endTime: "11:59 pm",
        openDateTimePicker: "",
      });
    } else if (showDateTimeInput === "endDate") {
      this.setState({ endDate: moment(e._d).format("MM/DD/YYYY") });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: moment(e._d).format("MM/DD/YYYY"),
      });
    } else {
      this.setState({
        [stateName]: moment(e._d).format("hh:mm a"),
        openDateTimePicker: stateName,
      });
    }
  };

  handleClick = (e) => {
    if (
      typeof this.datePickerWrapper === "undefined" &&
      this.datePickerWrapper === null
    ) {
      return;
    }
    if (
      !_.isNull(this.datePickerWrapper) &&
      !_.isUndefined(this.datePickerWrapper) &&
      this.datePickerWrapper &&
      !this.datePickerWrapper.contains(e.target)
    ) {
      this.setState({
        openDateTimePicker: "",
      });
    }
  };

  setQuestionAnswers = (data) => {
    let questionAns = [];
    // let mcqDropDownOptions=[];

    for (let i = 0; i < data.length; i++) {
      let mcqOptions = [{                text: "1 pt",
        value: 1,}];

      let modifiedName = data[i].type.replace(/-/g, " "); // Remove hyphens
      if (modifiedName === "yes no") {
        modifiedName = "yes or no"; // If name is "yes-no", set it to "yes"
      } else if (modifiedName === "short answer") {
        modifiedName = "short description";
      }

      let queAnsObj = {};
      queAnsObj.questionId = data[i].question_id;
      queAnsObj.followUpQue = data[i].follow_up_question;
      queAnsObj.isFollowUp = data[i].follow_up_question_id ? true : false;
      queAnsObj.questionType = {
        id: data[i].type_id,
        name: data[i].type,
        text: modifiedName,
      };
      queAnsObj.question = data[i].question;
      queAnsObj.answerId =
        data[i].answers &&
        data[i].answers.length &&
        data[i].answers[0].answer_id;
      queAnsObj.multipleMcqResponses = data[i].accept_multiple_responses
        ? data[i].accept_multiple_responses
        : false;
      let ansArr = [];

      if (data[i].answers && data[i].answers.length > 0) {
        if (data[i].type === "likert-scale") {
          if (data[i].answers[0].points === 1) {
            ansArr = [
              { label: "Strongly Disagree", points: 1, text: "1pt" },
              { label: "Strongly Agree", points: 5, text: "5pts" },
            ];
          } else {
            ansArr = [
              { label: "Strongly Disagree", points: 5, text: "5pts" },
              { label: "Strongly Agree", points: 1, text: "1pt" },
            ];
          }

        }else if (data[i].type === "opinion-scale") {

          if (data[i].answers.length > 10) {
            if (data[i].answers[0].points === 0) {
              ansArr = [
                {
                  label: data[i].answers[0].label,
                  points: data[i].answers[0].points,
                  text: "1pt",
                },
                {
                  label: data[i].answers[10].label,
                  points: data[i].answers[10].points,
                  text: "10pts",
                },
              ];
            } else {
              ansArr = [
                {
                  label: data[i].answers[10].label,
                  points: data[i].answers[10].points,
                  text: "10pts",
                },
                {
                  label: data[i].answers[0].label,
                  points: data[i].answers[0].points,
                  text: "1pt",
                },
              ];
            }
          }

        } else {
          for (let j = 0; j < data[i].answers.length; j++) {
            let ansObj = {
              points: data[i].answers[j].points,
              label: data[i].answers[j].value,
              text:
                data[i].answers[j].points > 1
                  ? data[i].answers[j].points + "pts"
                  : data[i].answers[j].points + "pt",
            };
            if (data[i].type === "multiple-choice") {
              if (data[i].answers[j].points) {
                queAnsObj.isMcqWeightage = true;
              }
              // const newPoint = {
              //   text: j + 1 > 1 ? j + 1 + "pts" : j + 1 + "pt",
              //   value: j + 1,
              // };
              // mcqDropDownOptions.push(newPoint);
              // mcqOptions.push(newPoint);
              queAnsObj.mcqDropDownOptions = mcqOptions;
            }
            ansArr.push(ansObj);
          }
        }
      }

      queAnsObj.answers = ansArr;
      queAnsObj.isupdated = false;

      questionAns.push(queAnsObj);
    }

    this.setState({ questionAnswers: questionAns }, () =>
      this.areAllValuesNotEmpty(this.state.questionAnswers)
    );
  };

  fetchQuestionAnswers = async (surveyId) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    await fetch(
      BASE_URL +
        (this.props.history?.location?.state?.type === "editsurveylibrary"
          ? GetSurveyLibraryQuestionAnswers
          : GetSurveyQuestionAnswers) +
        "/" +
        surveyId,
      {
        method: "GET",
        headers: { AUTHTOKEN: AuthToken },
      }
    )
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (this._isMounted) {
              if (data.questions.length) {
                this.setQuestionAnswers(data.questions);
              } else {
                this.setState({
                  questionAnswers: [
                  ],
                });
              }

              // this.setState({ surveyCategories: data.categories });
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  fetchSurveyCategories = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    await fetch(BASE_URL + GetSurveyCategories, {
      method: "GET",
      headers: { AUTHTOKEN: AuthToken },
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (this._isMounted) {
              this.setState({ surveyCategories: data.categories });
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  fetchQuestionTypes = async (editData) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    await fetch(BASE_URL + GetSurveyQuestionTypes, {
      method: "GET",
      headers: { AUTHTOKEN: AuthToken },
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            let questionTypes = data["question-types"];
            let modifiedQuestionTypes = questionTypes.map((questionType) => {
              let modifiedName = questionType.name.replace(/-/g, " "); // Remove hyphens
              if (modifiedName === "yes no") {
                modifiedName = "yes or no"; // If name is "yes-no", set it to "yes"
              } else if (modifiedName === "short answer") {
                modifiedName = "short description";
              }

              return {
                id: questionType.id,
                text: modifiedName,
                name: questionType.name,
              };
            });
            if (this._isMounted) {

              let typeindex = modifiedQuestionTypes.findIndex((item)=> { window.console.log("hi", item);return item.name == "opinion-scale"});

              if(this.state.companyId !== 1 && typeindex !== -1){
                modifiedQuestionTypes.splice(typeindex, 1);
              }
              
              this.setState({
                surveyQuestionTypes: modifiedQuestionTypes,
                // questionAnswers: [
                //   {
                //     questionType: modifiedQuestionTypes[0],
                //     question: "",
                //     isFollowUp: false,
                //   },
                // ],
              },()=>{    if(editData && editData.surveyId){
                this.fetchQuestionAnswers(editData.surveyId);
                // this.setState({step:2, isEdit:true});
              }else{
                this.setState({
                  questionAnswers: [
                    {
                      questionType: modifiedQuestionTypes[0],
                      question: "",
                      isFollowUp: false,
                    },
                  ],
                })
              }});
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  FocusAreaToggleButton = (e) => {
    this.setState({ selectedFocusAreaCheckBox: e });
  };

  changeLimitCheckBox = () => {
    this.setState({
      addLimitCheckbox: !this.state.addLimitCheckbox,
    });
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 1) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                  imageUpdate: 1,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 1MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  onChangeInput = (e) => {
    if (e.target.value.charAt(0) !== " ") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };

  onCheckboxChange = (element, value) => {
    const { exerciseFocusAreas } = this.state;
    if (exerciseFocusAreas.includes(element)) {
      let index = exerciseFocusAreas.findIndex((item) => item === element);
      if (index > -1) {
        exerciseFocusAreas.splice(index, 1);
      }
    } else {
      exerciseFocusAreas.push(element);
    }
    this.setState({
      exerciseFocusAreas: exerciseFocusAreas,
      selectedFocusAreaValue: value,
    });
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allExerciseFocusAreas } = this.state;
    if (this.state.exerciseFocusAreas.length === allExerciseFocusAreas.length) {
      invitedCompanies = [];
    } else {
      allExerciseFocusAreas.map((focusarea) => {
        invitedCompanies.push(focusarea.id);
      });
    }
    this.setState({
      exerciseFocusAreas: invitedCompanies,
    });
  };

  createSurvey = async (isLaunch) => {
    // e.preventDefault();
    this.setState({ loading: true });
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    const {
      title,
      description,
      imgSrc,
      selectedSurveyCategory,
      selectedSurveyPoint,
      isEdit,
      // imageUpdate,
      companyId,
      createdSurveyDetails,
      // isSurveyEdit,
      date,
      endDate,
      endType,
      participantLimit,
    } = this.state;

    // const focusAreas = exerciseFocusAreas.map((number) => number.toString());
    const surveyDetails = {
      title: title,
      survey_category: selectedSurveyCategory.id,
      description: description,
      points: selectedSurveyPoint.value,
      company_id: companyId,
    };
    if ((!isEdit || !isSurveyEdit) && !imgSrc.includes("cloudfront")) {
      surveyDetails.image = imgSrc;
    }

    // if (isEdit) {
    //   surveyDetails.image_update = imageUpdate;
    // }

    const launchSurvey = {
      launch_date: moment(date).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      end_type: endType,
      participant_limit: Number(participantLimit),
    };

    const type =
      this.props.history?.location?.state?.type;
    const isSurveyEdit =
      this.props.history.location.state &&
      this.props.history.location.state.isSurveyEdit &&
      this.props.history.location.state.isSurveyEdit;

    let url = isEdit
      ? BASE_URL +
        (type === "woliba" ||
        type === "organization" ||
        type == "editsurveylibrary"
          ? EditSurveyLibrary
          : UpdateSurvey) +
        `/${this.props.history.location.state.surveyId}`
      : isSurveyEdit
        ? BASE_URL +
        (type === "woliba" ||
        type === "organization" ||
        type == "editsurveylibrary"
          ? EditSurveyLibrary
          : UpdateSurvey) +
        `/${createdSurveyDetails.id}`
        : BASE_URL + CreateNewSurvey;

    await fetch(url, {
      method: isEdit || isSurveyEdit ? "PUT" : "POST",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(isLaunch ? launchSurvey : surveyDetails),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              if (isLaunch) {
                this.props.history.push("/company/surveys");
              } else {
                this.setState(
                  {
                    loading: false,
                    createdSurveyDetails: data.survey,
                    isSurveyEdit: true,
                  },
                  () => {
                    this.updateStep(2);
                    this.props.history.push({
                      pathname: "/company/surveys/launch-custom",
                      state: {
                        surveyId:
                          type === "woliba" ||
                          type === "organization" ||
                          type == "editsurveylibrary"
                            ? data.survey_library.id
                            : data.survey.id,
                        step: 2,
                        isSurveyEdit: true,
                        type: type,
                      },
                    });
                  }
                );
              }

              // toast.success(data.data[0]);
              // this.props.history.push("/education/home-workout");
            } else {
              // toast.error(data.data);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
  };

  isVideoUrl = (videoUrl) => {
    this.setState({ workOutVideoLink: videoUrl });
    const videoUrlRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com|twitch\.tv|facebook\.com)\/.+/;
    videoUrlRegex.test(videoUrl);
    if (videoUrlRegex.test(videoUrl)) {
      this.setState({
        isVideoUrl: true,
        isVideoUrlMsg: "",
      });
    } else {
      this.setState({
        isVideoUrl: false,
        isVideoUrlMsg: "Please enter valid URL",
      });
    }
  };

  renderHeading = () => (
    <ChallengeHeading style={{ alignItems: "center" }}>
      {" "}
      <HoverButton
        onClick={() => this.props.history.goBack()}
        svgPath={closeIcon()}
        title={"Close"}
      />{" "}
      <span
        style={{
          marginLeft: "25px",
          color: "#005C87",
          fontFamily: "Rubik-Medium",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        Launch a survey
      </span>
    </ChallengeHeading>
  );

  stepOneForm = () => {
    const {
      title,
      description,
      // workOutVideoLink,
      step,
      // isVideoUrlMsg,
      surveyCategories,
      selectedSurveyCategory,
      selectedSurveyPoint,
    } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="survey" />
            </div>
            <div className="form" style={{ paddingBottom: "75px" }}>
              <div className="heading">
                <div className="step" style={{ background: "#85C0EA" }}>
                  {step}
                </div>
                <div
                  className="headingName"
                  style={{
                    color: "#005C87",
                    fontFamily: "Rubik-Medium",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                  }}
                >
                  Introduce the Survey to your team
                </div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel
                    style={{
                      color: "#005C87",
                      fontFamily: "Rubik-Regular",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Title of the survey{<span>*</span>}
                  </StyledLabel>
                  <StyledInputV2
                    placeholder={t("Write title here...")}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    // maxLength="50"
                    style={{
                      background: "rgba(247, 245, 235, 0.50)",
                      border: "1px solid rgba(0, 92, 135, 0.30)",
                      color: "#005C87",
                      fontFamily: "Rubik-Regular",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",
                    }}
                  />
                </InputContainer>
                <StyledLabel
                  style={{
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Survey image{<span>*</span>}
                </StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <img alt="profilre-img" src={this.state.imgSrc}></img>
                    ) : (
                      <div
                        className="uploadImage"
                        style={{
                          background: "rgba(247, 245, 235, 0.50)",
                          border: "1px solid rgba(0, 92, 135, 0.30)",
                        }}
                      >
                        <InputImage
                          className="edit-profile-pic "
                          style={{ background: "#005C87" }}
                          imageReload={1}
                        >
                          <i className="addImg">{"Upload Photo"}</i>
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                    {this.state.imgSrc.length === 0 ? null : (
                      <RemoveImage onClick={this.removePhoto}>
                        {"Remove Image"}
                      </RemoveImage>
                    )}
                  </div>
                </UploadPhotoContainer>

                <StyledLabel
                  style={{
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <span style={{color: "#005C87"}}>Message to employees - template below:{<span style={{color: "red"}}>*</span>}</span>
                  <span style={{color: "#005c8766"}}>{this.state.description.length}/1500</span>

                </StyledLabel>

                <CommonTextArea
                  placeholder="Enter Message to employees"
                  rows="3"
                  name="description"
                  data-gramm_editor="false"
                  onChange={this.onChangeInput}
                  value={description}
                  maxLength={1500}
                  style={{
                    marginTop: "0px",
                    background: "rgba(247, 245, 235, 0.50)",
                    border: "1px solid rgba(0, 92, 135, 0.30)",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                />

                <StyledLabel
                  style={{
                    marginBottom: "0px",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Set survey category{<span>*</span>}
                </StyledLabel>

                <DropDown
                  data={surveyCategories}
                  placeholder="Select Option"
                  valueIndex={0}
                  dropdownStyle={{ top: "30px" }}
                  onSelectParameter={["name", "id"]}
                  itemValue={true}
                  activeValue={true}
                  title={selectedSurveyCategory.name}
                  displayValue={true}
                  valueString={""}
                  tileContainerStyle={{
                    width: "100%",
                    // background: "rgba(247, 245, 235, 0.50)",
                    position: "absolute",
                    border: "1px solid rgba(0, 92, 135, 0.30)",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  active={selectedSurveyCategory.id}
                  onSelect={(value) => {
                    this.setState({ selectedSurveyCategory: value });
                  }}
                  activityDropdownStyle={{
                    background: "rgba(247, 245, 235, 0.50)",
                  }}
                />

                <StyledLabel
                  style={{
                    marginBottom: "0px",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Set point value{<span>*</span>}
                </StyledLabel>

                <DropDown
                  data={surveypoints}
                  placeholder="Select Option"
                  valueIndex={0}
                  dropdownStyle={{ top: "30px" }}
                  onSelectParameter={["text", "value"]}
                  itemValue={true}
                  activeValue={true}
                  title={selectedSurveyPoint.text}
                  displayValue={true}
                  valueString={""}
                  tileContainerStyle={{
                    width: "100%",
                    // background: "rgba(247, 245, 235, 0.50)",
                  }}
                  active={selectedSurveyPoint.value}
                  onSelect={(value) => {
                    this.setState({ selectedSurveyPoint: value });
                  }}
                  activityDropdownStyle={{
                    background: "rgba(247, 245, 235, 0.50)",
                  }}
                />
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  onQueTypeSelect = (index, value, item) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].questionType = value;
    questionAnswers[index].isupdated = true;
    if (value.name === "opinion-scale") {
      questionAnswers[index].answers = [
        { label: "", points: 0, text: "0pts" },
        { label: "", points: 10, text: "10pts" },
      ];
    } else if (value.name === "multiple-choice") {
      questionAnswers[index].answers = [
        { label: "", points: 1, text: "1pt" },
        { label: "", points: 1, text: "1pt" },
      ];
      questionAnswers[index].mcqDropDownOptions = [
        { value: 1, text: "1pt" },
        // { value: 1, text: "1pt" },
      ];
      questionAnswers[index].isMcqWeightage = true;
      questionAnswers[index].multipleMcqResponses = false;
    } else if (value.name === "yes-no") {
      questionAnswers[index].answers = [
        { label: "Yes", points: 5, text: "5pts" },
        { label: "No", points: 1, text: "1pt" },
      ];
    } else if (value.name === "likert-scale") {
      questionAnswers[index].answers = [
        { label: "Strongly Disagree", points: 1, text: "1pt" },
        { label: "Strongly Agree", points: 5, text: "5pts" },
      ];
    }
    this.setState({ questionAnswers: questionAnswers });

    if (item.questionId) {
      // this.createSurveyQues(item, index, item.isFollowUp);
    }
  };

  onQueChange = (index, event) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].question = event.target.value;
    questionAnswers[index].isupdated = true;
    this.setState({ questionAnswers: questionAnswers });
    this.areAllValuesNotEmpty(questionAnswers);
  };

  onFollowUpToggle = (index, name, /*item*/) => {
    const { questionAnswers } = this.state;
    questionAnswers[index][name] = !questionAnswers[index][name];
    questionAnswers[index].isupdated = true;
    this.setState({ questionAnswers: questionAnswers }, () => {
      if (name == "multipleMcqResponses") {
        // this.createSurveyQues(item, index);

        if (questionAnswers[index]["multipleMcqResponses"]) {
          questionAnswers[index]["isMcqWeightage"] = false;
        } else {
          questionAnswers[index]["isMcqWeightage"] = true;

          // for (let i = 0; i < questionAnswers[index]["answers"].length; i++) {
          //   (questionAnswers[index]["answers"][i].text =
          //     i + 1 > 1 ? i + 1 + "pts" : i + 1 + "pt"), (questionAnswers[index]["answers"][i].points = i + 1);
          // }
          // {text:j + 1 > 1? (j + 1) + "pts": (j + 1) + "pt", value:j + 1}
          // this.setState({ questionAnswers: questionAnswers },()=>{this.onOpinionLabelBlur(index, "answers")});
        }
        this.setState({ questionAnswers: questionAnswers }, () => {
          // this.onOpinionLabelBlur(index, "answers");
        });
      } else if (name == "isMcqWeightage") {
        // this.onOpinionLabelBlur(index, "answers");
      }

      // name == "multipleMcqResponses" && this.createSurveyQues(item, index);name == "isMcqWeightage" && this.onOpinionLabelBlur(index, "answers")
    });
  };

  addNewQue = () => {
    let questionAnswers = [...this.state.questionAnswers];
    let allsurveyQuestionTypes = [...this.state.surveyQuestionTypes];
    let shortDesc = allsurveyQuestionTypes.filter(
      (item) => item.name === "short-answer"
    );
    questionAnswers.push({
      questionType: shortDesc[0],
      question: "",
      isFollowUp: false,
    });
    this.setState({ questionAnswers: questionAnswers }, () =>
      this.areAllValuesNotEmpty(this.state.questionAnswers)
    );
  };

  onQueBlur = (index, item, isFollowUp) => {
    this.createSurveyQues(item, index, isFollowUp);
  };

  onFollowUpQueChange = (index, event) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].followUpQue = event.target.value;
    this.setState({ questionAnswers: questionAnswers });
  };

  createSurveyQues = async (item, index, isFollowUp,isReorder) => {
    // e.preventDefault();
    // this.setState({ loading: true });
    const AuthToken = localStorage.getItem("AUTH_TOKEN");

    // const focusAreas = exerciseFocusAreas.map((number) => number.toString());
    let surveyQue = {
      // question:item.question,
      // follow_up_question : item.followUpQue,
      entity_type:
        this.props.history?.location?.state?.type === "editsurveylibrary"
          ? "survey_library"
          : "survey",
      type: item.questionType.id,
      entity_id: this.props.history.location.state.surveyId,
      accept_multiple_responses: item.multipleMcqResponses,
      weight: index + 1,
    };

    if (isFollowUp) {
      surveyQue.follow_up_question = item.followUpQue;
    } else {
      surveyQue.question = item.question;
    }

    let url = item.questionId
      ? BASE_URL + CreateSurveyQuestion + `/${item.questionId}`
      : BASE_URL + CreateSurveyQuestion;

    await fetch(url, {
      method: item.questionId ? "PUT" : "POST",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(surveyQue),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              toast.success(
                item.questionId
                  ? "Question Updated Successfully"
                  : "Question Saved Successfully"
              );
              const { questionAnswers } = this.state;
              questionAnswers[index].questionId = data.question.question_id;
              this.setState({ questionAnswers: questionAnswers }, () =>{
                if(item.questionType.name == "short-answer"){
                  questionAnswers[index].isupdated = false;
                  this.setState({ questionAnswers: questionAnswers })
                }else{
                  !this.state.isActiveOrUpcoming && !isReorder ? this.onOpinionLabelBlur(index, "answers") : null
                }
                // item.questionType.name == "yes-no" ||
                // item.questionType.name == "likert-scale"
                //   ? this.onOpinionLabelBlur(index, "answers")
                //   :  item.questionType.name == "opinion-scale" ? this.onOpinionLabelBlur(index, "answers") :null
              }
              );
            } else {
              // toast.error(data.data);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  createSurveyAns = async (item, index, ans) => {
    // e.preventDefault();
    // this.setState({ loading: true });
    const AuthToken = localStorage.getItem("AUTH_TOKEN");

    let finalAns = [];

    for (let i = 0; i < ans.length; i++) {
      let ansObj = {};

      // if(!item.isMcqWeightage && item.questionType.name == "multiple-choice"){
      //   ansObj.value = ans[i].label;
      //   ansObj.points = 1;
      // }else{
      //   ansObj.value = ans[i].label;
      //   ansObj.points = ans[i].points;
      // }

      if (
        item.multipleMcqResponses &&
        item.questionType.name == "multiple-choice"
      ) {
        ansObj.value = ans[i].label;
        ansObj.points = 1;
      } else {
        ansObj.value = ans[i].label;
        ansObj.points = ans[i].points;
      }

      finalAns.push(ansObj);
    }

    let surveyAns = {
      question_id: item.questionId,
      answer: finalAns,
    };

    if (item.questionType.name == "likert-scale") {
      if (ans?.[0]?.points === 1) {
        surveyAns.answer = likertScaleValues;
      } else {
        surveyAns.answer = likertScaleValues
          .slice()
          .sort((a, b) => b.points - a.points);
      }
    }

    if (item.questionType.name == "opinion-scale") {
      if (ans?.[0]?.points === 0) {
        surveyAns.answer = opinionScaleValues;
      } else {
        surveyAns.answer = opinionScaleValues
          .slice()
          .sort((a, b) => b.points - a.points);
      }

      for (let i = 0; i <= 10; i++) {
        surveyAns.answer[i].value = i;
      }

      for (let i = 0; i <= 10; i++) {
        surveyAns.answer[i].value = i;

        if (i === 0 || i === 10) {
          surveyAns.answer[i].label = item.answers[i === 0 ? 0 : 1].label;
        } else {
          surveyAns.answer[i].label = "";
        }

        // if(i < 6){
        //   surveyAns.answer[i].label = item.answers[0].label;
        // }else{
        //   surveyAns.answer[i].label = item.answers[1].label;

        // }
      }

      // surveyAns.answer[0].value = item.answers[0].label;
      // surveyAns.answer[1].value = item.answers[0].label;
      // surveyAns.answer[2].value = item.answers[0].label;
      // surveyAns.answer[3].value = item.answers[0].label;
      // surveyAns.answer[4].value = item.answers[0].label;
      // surveyAns.answer[5].value = item.answers[0].label;
      // surveyAns.answer[6].value = item.answers[1].label;
      // surveyAns.answer[7].value = item.answers[1].label;
      // surveyAns.answer[8].value = item.answers[1].label;
      // surveyAns.answer[9].value = item.answers[1].label;
      // surveyAns.answer[10].value = item.answers[1].label;
    }

    let url = item.answerId
      ? BASE_URL + CreateSurveyAnswer + `/${item.answerId}`
      : BASE_URL + CreateSurveyAnswer;

    await fetch(url, {
      method: item.answerId ? "PUT" : "POST",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(surveyAns),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              toast.success(
                item.answerId
                  ? "Answer Updated Successfully"
                  : "Answer Saved Successfully"
              );
              const { questionAnswers } = this.state;
              questionAnswers[index].isupdated = false;
              questionAnswers[index].answerId = data.answer[0].answer_id;
              this.setState({ questionAnswers: questionAnswers });
            } else if (res.data.message) {
              toast.error(res.data.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  onOpinionLabelChange = (index, e, labelIndex) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].answers[labelIndex].label = e.target.value;
    questionAnswers[index].isupdated = true;
    this.setState({ questionAnswers: questionAnswers });
  };

  onOpinionLabelBlur = (index, name) => {
    const { questionAnswers } = this.state;
    this.createSurveyAns(
      questionAnswers[index],
      index,
      questionAnswers[index][name]
    );
  };

  onOpinionPointChange = (index, value, labelIndex) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;
    // questionAnswers[index].opinionScale[labelIndex].value = value.value;
    if (labelIndex === 0 && value.value === 0) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[1].text = "10pts";
      questionAnswers[index].answers[1].points = 10;
    } else if (labelIndex === 0 && value.value === 10) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[1].text = "0pts";
      questionAnswers[index].answers[1].points = 0;
    }
    if (labelIndex === 1 && value.value === 0) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[0].text = "10pts";
      questionAnswers[index].answers[0].points = 10;
    } else if (labelIndex === 1 && value.value === 10) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[0].text = "0pts";
      questionAnswers[index].answers[0].points = 0;
    }

    this.setState({ questionAnswers: questionAnswers });
  };

  onLabelChange = (index, e, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;
    questionAnswers[index][name][labelIndex].label = e.target.value;
    this.setState({ questionAnswers: questionAnswers });
  };

  onLikeartPointChange = (index, value, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;
    // questionAnswers[index].likertScale[labelIndex].value = value.value;
    if (labelIndex === 0 && value.value === 1) {
      questionAnswers[index][name][labelIndex].text = value?.text;
      questionAnswers[index][name][labelIndex].points = value.value;
      questionAnswers[index][name][1].text = "5pts";
      questionAnswers[index][name][1].points = 5;
    } else if (labelIndex === 0 && value.value === 5) {
      questionAnswers[index][name][labelIndex].text = value.text;
      questionAnswers[index][name][labelIndex].points = value.value;
      questionAnswers[index][name][1].text = "1pts";
      questionAnswers[index][name][1].points = 1;
    }
    if (labelIndex === 1 && value.value === 1) {
      questionAnswers[index][name][labelIndex].text = value.text;
      questionAnswers[index][name][labelIndex].points = value.value;
      questionAnswers[index][name][0].text = "5pts";
      questionAnswers[index][name][0].points = 5;
    } else if (labelIndex === 1 && value.value === 5) {
      questionAnswers[index][name][labelIndex].text = value.text;
      questionAnswers[index][name][labelIndex].points = value.value;
      questionAnswers[index][name][0].text = "1pts";
      questionAnswers[index][name][0].points = 1;
    }

    this.setState({ questionAnswers: questionAnswers }, () =>{
      //this.onOpinionLabelBlur(index, "answers");
    });
  };

  onMcqPointChange = (index, value, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;

    // for (let i = 0; i < questionAnswers[index][name].length; i++) {
    //   if (questionAnswers[index][name][i].points === value.value) {
    //     (questionAnswers[index][name][i].text =
    //       questionAnswers[index][name][labelIndex].text),
    //     (questionAnswers[index][name][i].points =
    //         questionAnswers[index][name][labelIndex].points);
    //   }
    // }
    questionAnswers[index][name][labelIndex].text = value.text;
    questionAnswers[index][name][labelIndex].points = value.value;

    this.setState({ questionAnswers: questionAnswers }, () =>
      this.onOpinionLabelBlur(index, "answers")
    );
  };

  deleteMcqOption = (item, index, ind) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].answers.splice(ind, 1);

    // let modifiedmcqpoints = [];
    // for (let i = 0; i < questionAnswers[index].answers.length; i++) {
    //   let modifiedObj = {
    //     value: i + 1,
    //     text:
    //       i + 1 + (questionAnswers[index].answers.length > 1 ? "pts" : "pt"),
    //   };
    //   modifiedmcqpoints.push(modifiedObj);

    //   if (i >= ind) {
    //     let macqAnsObj = {
    //       points: questionAnswers[index].answers[i].points - 1,
    //       text:
    //         questionAnswers[index].answers[i].points -
    //         1 +
    //         (questionAnswers[index].answers[i].points > 1 ? "pts" : "pt"),
    //       label: questionAnswers[index].answers[i].label,
    //     };

    //     questionAnswers[index].answers[i] = macqAnsObj;
    //   }
    // }

    // questionAnswers[index].mcqDropDownOptions = modifiedmcqpoints;

    this.setState(
      { questionAnswers: questionAnswers },
      () => this.onOpinionLabelBlur(index, "answers")
    );
  };

  addOptions = (index) => {
    const { questionAnswers, mcqpoints } = this.state;

    if (questionAnswers[index].answers.length < 10) {
      const newOption = {
        label: "",
        points:  1,
        text:
          1 + "pt",
      };

      // const newPoint = { text: newOption.text, value: newOption.points };
      // mcqpoints.push(newPoint);
      // questionAnswers[index].mcqDropDownOptions.push(newPoint);

      questionAnswers[index].answers.push(newOption);

      // Update the state with the new option
      this.setState({ questionAnswers: questionAnswers, mcqpoints: mcqpoints });
    }
  };

  deleteQuestionAns = async (questionId) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    await fetch(BASE_URL + DeleteSurveyQuestionAnswers + "/" + questionId, {
      method: "DELETE",
      headers: { AUTHTOKEN: AuthToken },
    })
      .then((res) => {
        if (res.status === 204) {
          toast.success("Question Deleted Successfully");
          this.fetchQuestionAnswers(this.props.history.location.state.surveyId);
        } else {
          toast.error(res.status);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  stepTwoForm = () => {
    const {
      step,
      surveyQuestionTypes,
      questionAnswers,
      // mcqpoints,
      isActiveOrUpcoming,
      // isFollowUp,
      // selectedSurveyCategory
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="survey" />
            </div>
            <div className="form">
              <div
                className="heading"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <div className="step" style={{ background: "#85C0EA" }}>
                    {step}
                  </div>
                  <div
                    className="headingName"
                    style={{
                      color: "#005C87",
                      fontFamily: "Rubik-Medium",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                    }}
                  >
                    Questions to ask
                  </div>
                </div>
                {!isActiveOrUpcoming && (
                  <div style={{ display: "flex", marginRight: "15px" }}>
                    <HoverButton
                      title={"Add Question"}
                      svgPath={addQueIcon()}
                      onClick={this.scrollToDiv}
                    />
                    <HoverButton
                      title={"Reorder Questions"}
                      svgPath={reorderIcon()}
                      onClick={() =>
                        this.setState({ showQueReorderPopUp: true })
                      }
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  background: "#F7F5EB",
                  height: "74px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "rgba(0, 92, 135, 0.60)",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Add atleast 5 question for better data generation. min. 2 questions are required
                </span>
              </div>

              <div className="formBody" style={{ padding: "0px" }}>
                {questionAnswers.length ? questionAnswers.map((item, index) => (
                  <React.Fragment key={index}>
                    {/* <div></div> */}
                    <QueAndCard>
                      <div className="header">
                        <div>
                          <span className="title">Question {index + 1}</span>
                        </div>
                        {!isActiveOrUpcoming && (
                          <div
                            onClick={() => {
                              this.deleteQuestionAns(item.questionId);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="delete-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M16.563 2.5h-3.438v-.625C13.125.839 12.285 0 11.25 0h-2.5C7.714 0 6.875.84 6.875 1.875V2.5H3.437c-.862 0-1.562.7-1.562 1.563v1.25c0 .345.28.625.625.625h15c.345 0 .625-.28.625-.625v-1.25c0-.863-.7-1.563-1.563-1.563Zm-8.438-.625c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625V2.5h-3.75v-.625ZM3.06 7.188c-.111 0-.2.093-.195.204l.516 10.822A1.873 1.873 0 0 0 5.254 20h9.491a1.873 1.873 0 0 0 1.873-1.786l.515-10.822a.195.195 0 0 0-.195-.205H3.061ZM12.5 8.75a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.126 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.125 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Z"
                                  fill="#005C87"
                                  fillOpacity=".6"
                                />
                              </svg>
                            </span>
                          </div>
                        )}
                      </div>
                      <Border
                        style={{
                          background: "rgba(0, 92, 135, 0.30)",
                          margin: "15px 0px",
                        }}
                      />
                      <DropDown
                        data={surveyQuestionTypes}
                        label={"Your type of question"}
                        placeholder="Select Option"
                        valueIndex={0}
                        dropdownStyle={{ top: "30px" }}
                        onSelectParameter={["text", "id"]}
                        itemValue={true}
                        activeValue={true}
                        title={item && item.questionType && item.questionType.text &&  item.questionType.text}
                        displayValue={true}
                        valueString={""}
                        tileContainerStyle={{
                          width: "100%",
                          // background: "rgba(247, 245, 235, 0.50)",
                          padding: "0px",
                          // color:"#005C87"
                        }}
                        background={
                          isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                        }
                        active={item && item.questionType && item.questionType.id && item.questionType.id}
                        onSelect={(value) => {
                          this.onQueTypeSelect(index, value, item);
                        }}
                        disabled={isActiveOrUpcoming}
                        height={"60px"}
                        icon={
                          item && item.questionType && item.questionType.name && item.questionType.name == "short-answer" ? (
                            shortDescIcon()
                          ) : item && item.questionType && item.questionType.name && item.questionType.name == "opinion-scale" ? (
                            opinionScaleIcon()
                          ) : item && item.questionType && item.questionType.name && item.questionType.name == "multiple-choice" ? (
                            mcqIcon()
                          ) : item && item.questionType && item.questionType.name && item.questionType.name == "yes-no" ? (
                            yesnoIcon()
                          ) : item && item.questionType && item.questionType.name && item.questionType.name == "likert-scale" ? (
                            likertScaleIcon()
                          ) : (
                            <div
                              style={{
                                background: "white",
                                width: "40px",
                                height: "40px",
                                borderRadius: "3px",
                              }}
                            ></div>
                          )
                        }
                      />
                      <InputContainer>
                        <StyledLabel
                          style={{
                            color: "#005C87",
                            fontFamily: "Rubik-Regular",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                          }}
                        >
                          What would you like to ask?{<span>*</span>}
                        </StyledLabel>
                        <StyledInputV2
                          placeholder={"Enter The question you want ask"}
                          name="title"
                          onChange={(e) => this.onQueChange(index, e)}
                          value={item.question}
                          // maxLength="50"
                          style={{
                            background: "rgba(247, 245, 235, 0.50)",
                            border: "1px solid rgba(0, 92, 135, 0.30)",
                            color: "#005C87",
                            fontFamily: "Rubik-Regular",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                          }}
                          // onBlur={() => this.onQueBlur(index, item)}
                        />
                      </InputContainer>

                      {item && item.questionType && item.questionType.name && item.questionType.name && item.questionType.name === "opinion-scale" ? (
                        <div className="opinion-scale">
                          <div className="header">
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              10 Point Scale
                            </span>
                          </div>
                          <div className="point-scale">
                            <div className="point-label">
                              {/* <div>{`"${item &&  item.opinionScale  && item.opinionScale[0].points}" label`}</div> */}
                              <div
                                style={{
                                  color: "rgba(0, 92, 135, 0.60)",
                                  fontFamily: "Rubik-Regular",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                }}
                              >{`"0" label`}</div>
                              <div className="input-label">
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={"eg. it was bad"}
                                    name="title"
                                    onChange={(e) =>
                                      this.onOpinionLabelChange(index, e, 0)
                                    }
                                    value={
                                      item &&
                                      item.answers &&
                                      item.answers.length &&
                                      item.answers[0].label
                                    }
                                    maxLength="50"
                                    style={{
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      color: isActiveOrUpcoming
                                        ? "rgba(0, 92, 135,0.6)"
                                        : "rgba(0, 92, 135)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                    // onBlur={() =>this.onOpinionLabelBlur(index, "answers")}
                                  />
                                </InputContainer>
                              </div>
                            </div>
                            <div className="point-dropdown">
                              <DropDown
                                data={opinionpoints}
                                // label={"Your type of question"}
                                placeholder="Select Option"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "id"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[0] &&
                                  item.answers[0].text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[0] &&
                                  item.answers[0].points
                                }
                                onSelect={(value) => {
                                  this.onOpinionPointChange(index, value, 0);
                                  // this.onOpinionLabelBlur(index, "answers");
                                }}
                                height={"60px"}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "20px 25px 0px 25px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="18"
                                viewBox="0 0 44 18"
                                fill="none"
                              >
                                <rect
                                  y="6"
                                  width="44"
                                  height="6"
                                  rx="3"
                                  fill="#005C87"
                                />
                                <circle
                                  cx="22"
                                  cy="9"
                                  r="8.5"
                                  fill="#F4AAA9"
                                  stroke="#fff"
                                />
                                <path
                                  d="M20 7h4m-6 2h8m-6 2h4"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            <div className="point-label">
                              {/* <div>{`"${item &&  item.answers && item.answers[1].points}" label`}</div> */}
                              <div
                                style={{
                                  color: "rgba(0, 92, 135)",
                                  fontFamily: "Rubik-Regular",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                }}
                              >{`"10" label`}</div>
                              <div className="input-label">
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={"eg. it was Perfect"}
                                    name="title"
                                    onChange={(e) =>
                                      this.onOpinionLabelChange(index, e, 1)
                                    }
                                    value={
                                      item &&
                                      item.answers &&
                                      item.answers.length &&
                                      item.answers[1] &&
                                      item.answers[1].label
                                    }
                                    maxLength="50"
                                    style={{
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      color: isActiveOrUpcoming
                                        ? "rgba(0, 92, 135,0.6)"
                                        : "rgba(0, 92, 135)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                    // onBlur={() =>
                                    //   this.onOpinionLabelBlur(index, "answers")
                                    // }
                                  />
                                </InputContainer>
                              </div>
                            </div>

                            <div className="point-dropdown">
                              <DropDown
                                data={opinionpoints}
                                // label={"Your type of question"}
                                placeholder="Select Option"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "value"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].points
                                }
                                onSelect={(value) => {
                                  this.onOpinionPointChange(index, value, 1);
                                  // this.onOpinionLabelBlur(index, "answers");
                                }}
                                height={"60px"}
                              />
                            </div>
                          </div>
                        </div>
                      ) : item && item.questionType && item.questionType.name && item.questionType.name && item.questionType.name === "likert-scale" ? (
                        <div className="opinion-scale">
                          <div className="header">
                            <span className="title">5 Point Scale</span>
                          </div>
                          <div className="point-scale">
                            <div
                              className="point-label"
                              style={{ width: "auto" }}
                            >
                              {/* <div>{`"${item.opinionScale[0].points}" label`}</div> */}
                              <div
                                className="input-label"
                                style={{ width: "200px" }}
                              >
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={""}
                                    name="title"
                                    readOnly
                                    onChange={(e) =>
                                      this.onLabelChange(index, e, 0, "answers")
                                    }
                                    value={
                                      item &&
                                      item.answers &&
                                      item.answers.length &&
                                      item.answers[0] &&
                                      item.answers[0].label
                                    }
                                    maxLength="50"
                                    style={{
                                      background: "rgba(0, 92, 135, 0.05)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      width: "200px",
                                      color: "rgba(0, 92, 135, 0.60)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                  />
                                </InputContainer>
                              </div>
                            </div>
                            <div
                              className="point-dropdown"
                              style={{ marginTop: "0px" }}
                            >
                              <DropDown
                                data={likeartpoints}
                                // label={"Your type of question"}
                                placeholder="Select Option"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "value"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[0] &&
                                  item.answers[0].text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[0] &&
                                  item.answers[0].points
                                }
                                onSelect={(value) => {
                                  this.onLikeartPointChange(
                                    index,
                                    value,
                                    0,
                                    "answers"
                                  );
                                }}
                                height={"60px"}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                // onBlur={()=>this.onOpinionLabelBlur(index, "answers")}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "0px 25px 0px 25px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="18"
                                viewBox="0 0 44 18"
                                fill="none"
                              >
                                <rect
                                  y="6"
                                  width="44"
                                  height="6"
                                  rx="3"
                                  fill="#005C87"
                                />
                                <circle
                                  cx="22"
                                  cy="9"
                                  r="8.5"
                                  fill="#F4AAA9"
                                  stroke="#fff"
                                />
                                <path
                                  d="M20 7h4m-6 2h8m-6 2h4"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            <div
                              className="point-label"
                              style={{ width: "auto" }}
                            >
                              {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                              <div
                                className="input-label"
                                style={{ width: "200px" }}
                              >
                                <InputContainer>
                                  <StyledInputV2
                                    readOnly
                                    placeholder={""}
                                    name="title"
                                    onChange={(e) =>
                                      this.onLabelChange(index, e, 1, "answers")
                                    }
                                    value={
                                      item &&
                                      item.answers &&
                                      item.answers.length &&
                                      item.answers[1] &&
                                      item.answers[1].label
                                    }
                                    // maxLength="50"
                                    style={{
                                      background: "rgba(0, 92, 135, 0.05)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      width: "200px",
                                      color: "rgba(0, 92, 135, 0.60)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                  />
                                </InputContainer>
                              </div>
                            </div>

                            <div
                              className="point-dropdown"
                              style={{ marginTop: "0px" }}
                            >
                              <DropDown
                                data={likeartpoints}
                                // label={"Your type of question"}
                                placeholder="Select Option"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "value"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].points
                                }
                                onSelect={(value) => {
                                  this.onLikeartPointChange(
                                    index,
                                    value,
                                    1,
                                    "answers"
                                  );
                                }}
                                height={"60px"}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                              />
                            </div>
                          </div>
                        </div>
                      ) : item && item.questionType && item.questionType.name && item.questionType.name && item.questionType.name === "multiple-choice" ? (
                        <div className="opinion-scale">
                          <div className="header" style={{ marginTop: "15px" }}>
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              Options
                            </span>
                          </div>
                          {item &&
                            item.answers &&
                            item.answers.map((ans, ind) => (
                              <div key={ind} className="point-scale">
                                <div className="options">
                                  {String.fromCharCode(ind + 97)}
                                </div>
                                <div
                                  className="point-label"
                                  style={{ width: "300px" }}
                                >
                                  {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                                  <div className="input-label">
                                    <InputContainer>
                                      <StyledInputV2
                                        placeholder={"Add Option"}
                                        name="title"
                                        onChange={(e) =>
                                          this.onLabelChange(
                                            index,
                                            e,
                                            ind,
                                            "answers"
                                          )
                                        }
                                        value={ans.label}
                                        maxLength="50"
                                        style={{
                                          background: isActiveOrUpcoming
                                            ? "rgba(0, 92, 135, 0.05)"
                                            : "rgba(247, 245, 235, 0.50)",
                                          border:
                                            "1px solid rgba(0, 92, 135, 0.30)",
                                          color: isActiveOrUpcoming
                                            ? "rgba(0, 92, 135, 0.6)"
                                            : "#005C87",
                                          fontFamily: "Rubik-Medium",
                                          fontSize: "16px",
                                          fontWeight: 400,
                                          lineHeight: "20px",
                                        }}
                                        // onBlur={() =>
                                        //   this.onOpinionLabelBlur(
                                        //     index,
                                        //     "answers"
                                        //   )
                                        // }
                                        background={
                                          isActiveOrUpcoming &&
                                          "rgba(0, 92, 135, 0.05)"
                                        }
                                        disabled={isActiveOrUpcoming}
                                      />
                                    </InputContainer>
                                  </div>
                                </div>

                                {!item.multipleMcqResponses && (
                                  <div
                                    className="point-dropdown"
                                    style={{ marginTop: "0px" }}
                                  >
                                    <DropDown
                                      data={item.mcqDropDownOptions}
                                      // label={"Your type of question"}
                                      placeholder="Select Option"
                                      valueIndex={0}
                                      dropdownStyle={{ top: "30px" }}
                                      onSelectParameter={["text", "value"]}
                                      itemValue={true}
                                      activeValue={true}
                                      title={ans.text}
                                      displayValue={true}
                                      valueString={""}
                                      tileContainerStyle={{
                                        width: "110px",
                                        // background: "#F7F5EB",
                                        padding: "0px",
                                        marginRight: "25px",
                                      }}
                                      background={
                                        isActiveOrUpcoming &&
                                        "rgba(0, 92, 135, 0.05)"
                                      }
                                      disabled={isActiveOrUpcoming}
                                      active={ans.points}
                                      onSelect={(value) => {
                                        this.onMcqPointChange(
                                          index,
                                          value,
                                          ind,
                                          "answers"
                                        );
                                      }}
                                      height={"60px"}
                                    />
                                  </div>
                                )}

                                {item.answers.length > 2 &&
                                  !isActiveOrUpcoming && (
                                  <div
                                    onClick={() => {
                                      this.deleteMcqOption(item, index, ind);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <span className="delete-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M16.563 2.5h-3.438v-.625C13.125.839 12.285 0 11.25 0h-2.5C7.714 0 6.875.84 6.875 1.875V2.5H3.437c-.862 0-1.562.7-1.562 1.563v1.25c0 .345.28.625.625.625h15c.345 0 .625-.28.625-.625v-1.25c0-.863-.7-1.563-1.563-1.563Zm-8.438-.625c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625V2.5h-3.75v-.625ZM3.06 7.188c-.111 0-.2.093-.195.204l.516 10.822A1.873 1.873 0 0 0 5.254 20h9.491a1.873 1.873 0 0 0 1.873-1.786l.515-10.822a.195.195 0 0 0-.195-.205H3.061ZM12.5 8.75a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.126 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.125 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Z"
                                          fill="#005C87"
                                          fillOpacity=".6"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}

                          <div className="more-option">
                            <span
                              onClick={() =>
                                !isActiveOrUpcoming && this.addOptions(index)
                              }
                              style={{
                                color:
                                  isActiveOrUpcoming &&
                                  "rgba(0, 92, 135, 0.60)",
                                cursor: isActiveOrUpcoming && "default",
                              }}
                            >
                              Add More Option (Max 10)
                            </span>
                          </div>

                          {/* <div className="toggle-btn">
                            <ToggleButton style={{ position: "unset" }} disabled={isActiveOrUpcoming}>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.isMcqWeightage}
                                  onChange={() => {
                                    !isActiveOrUpcoming && this.onFollowUpToggle(index,"isMcqWeightage",item);
                                  }}
                                  disabled={isActiveOrUpcoming}
                                />
                                <span className="slider round"></span>
                              </label>
                            </ToggleButton>
                            <div className="toggle-label">
                            Do you want to set weightage for Options
                            </div>
                          </div> */}

                          <div className="toggle-btn">
                            <ToggleButton
                              style={{ position: "unset" }}
                              disabled={isActiveOrUpcoming}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.multipleMcqResponses}
                                  onChange={() => {
                                    !isActiveOrUpcoming &&
                                      this.onFollowUpToggle(
                                        index,
                                        "multipleMcqResponses",
                                        item
                                      );
                                    // this.createSurveyQues(item, index);
                                  }}
                                  disabled={isActiveOrUpcoming}
                                />
                                <span className="slider round"></span>
                              </label>
                            </ToggleButton>
                            <div className="toggle-label">
                              Allow respondents to select multiple answers
                            </div>
                          </div>
                        </div>
                      ) : item && item.questionType && item.questionType.name && item.questionType.name && item.questionType.name === "yes-no" ? (
                        <div
                          className="opinion-scale"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="header" style={{ marginTop: "0px" }}>
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              Options
                            </span>
                          </div>

                          {item &&
                            item.answers &&
                            item.answers.map((ans, ind) => (
                              <div key={ind} className="point-scale">
                                <div className="options">
                                  {String.fromCharCode(ind + 97)}
                                </div>
                                <div
                                  className="point-label"
                                  style={{ width: "300px" }}
                                >
                                  {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                                  <div className="input-label">
                                    <InputContainer>
                                      <StyledInputV2
                                        placeholder={""}
                                        readOnly
                                        name="title"
                                        onChange={(e) =>
                                          this.onLabelChange(
                                            index,
                                            e,
                                            ind,
                                            "answers"
                                          )
                                        }
                                        value={ans.label}
                                        // maxLength="50"
                                        style={{
                                          background: "rgba(0, 92, 135, 0.05)",
                                          border:
                                            "1px solid rgba(0, 92, 135, 0.30)",
                                          color: "rgba(0, 92, 135, 0.60)",
                                          fontFamily: "Rubik-Medium",
                                          fontSize: "16px",
                                          fontWeight: 400,
                                          lineHeight: "20px",
                                        }}
                                      />
                                    </InputContainer>
                                  </div>
                                </div>

                                <div
                                  className="point-dropdown"
                                  style={{ marginTop: "0px" }}
                                >
                                  <DropDown
                                    data={likeartpoints}
                                    // label={"Your type of question"}
                                    placeholder="Select Option"
                                    valueIndex={0}
                                    dropdownStyle={{ top: "30px" }}
                                    onSelectParameter={["text", "value"]}
                                    itemValue={true}
                                    activeValue={true}
                                    title={ans.text}
                                    displayValue={true}
                                    valueString={""}
                                    tileContainerStyle={{
                                      width: "110px",
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      padding: "0px",
                                      marginRight: "25px",
                                    }}
                                    active={ans.points}
                                    onSelect={(value) => {
                                      this.onLikeartPointChange(
                                        index,
                                        value,
                                        ind,
                                        "answers"
                                      );
                                      // this.onOpinionLabelBlur(index, "answers");
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                    height={"60px"}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : null}

                      {/* {item.questionType.name != "short-answer" && <div className="follow-up">
                        <div className="title">
                          <span>Would you like more detail?</span>
                        </div>
                        <div className="toggle-btn">
                          <ToggleButton style={{ position: "unset" }} disabled={isActiveOrUpcoming}>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item.isFollowUp}
                                onChange={() => {
                                  this.onFollowUpToggle(index, "isFollowUp", item);
                                }}
                                disabled={isActiveOrUpcoming}
                              />
                              <span className="slider round"></span>
                            </label>
                          </ToggleButton>
                          <div className="toggle-label">
                            Ask follow-up question
                          </div>
                        </div>
                      </div>}

                      {(item.isFollowUp && item.questionType.name != "short-answer") && (
                        <div style={{ marginTop: "15px" }}>
                          <StyledInputV2
                            placeholder={"eg. What would you suggest?"}
                            name="title"
                            onChange={(e)=>{this.onFollowUpQueChange(index, e); }}
                            value={item.followUpQue}
                            // maxLength="50"
                            style={{
                              background: "rgba(247, 245, 235, 0.50)",
                              border: "1px solid rgba(0, 92, 135, 0.30)",
                              color: "#005C87",fontFamily: "Rubik-Medium",fontSize: "16px",fontWeight: 400,lineHeight: "24px"
                            }}
                            onBlur={()=>{this.onQueBlur(index, item, true);}}
                          />
                        </div>
                      )} */}

                      <div className="save-buton"><button style={{opacity:(!this.validateSingleQuestionAnswers(index) || !item.isupdated)  && "0.5"}} disabled={!this.validateSingleQuestionAnswers(index) || !item.isupdated} className="save-btn" onClick={()=>this.onQueBlur(index, item,item.isFollowUp)}>Update</button></div>
                    </QueAndCard>

                    <div
                      style={{ height: "25px", background: "#F7F5EB" }}
                    ></div>
                  </React.Fragment>
                )):null}

                {!isActiveOrUpcoming && (
                  <div className="addex" ref={this.targetDivRef}>
                    <button onClick={this.addNewQue}>+ Add New Question</button>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: "",
      });
    } else {
      this.setState({
        openDateTimePicker: value,
      });
    }
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  stepThreeForm = () => {
    const {
      // title,
      // description,
      // workOutVideoLink,
      step,
      isLaunchNow,
      isSchedule,
      openDateTimePicker,
      date,
      showDateTimeInput,
      endType,
      endDate,
      isActiveOrUpcoming,
      createdSurveyDetails,
      // isVideoUrlMsg,
      // surveyCategories,
      // selectedSurveyCategory,
      // selectedSurveyPoint,
    } = this.state;
    // const { t } = this.props;

    const yesterday = Datetime.moment().subtract(23, "hour");
    const valid = (currentDate) =>
      showDateTimeInput === "date"
        ? currentDate.isAfter(Datetime.moment())
        : currentDate.isAfter(yesterday);
    const validEndDta = (currentDate) =>
      showDateTimeInput === "date"
        ? currentDate.isAfter(Datetime.moment())
        : currentDate.isAfter(this.state.date);
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={3} type="survey" isLaunch={true} />
            </div>
            <div className="form" style={{ paddingBottom: "75px" }}>
              <div className="heading">
                <div className="step" style={{ background: "#85C0EA" }}>
                  {step}
                </div>
                <div
                  className="headingName"
                  style={{
                    color: "#005C87",
                    fontFamily: "Rubik-Medium",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                  }}
                >
                  Set the date and Launch Survey
                </div>
              </div>
              <div className="formBody">
                <StyledLabel style={{ color: "#005C87" }}>
                  {this.props.t("When would you like to launch your survey?")}
                </StyledLabel>

                <div className="radioButtonDiv">
                  <span>
                    <RadioButton
                      id="1"
                      handler={() =>
                        this.setState({
                          isLaunchNow: !isLaunchNow,
                          isSchedule: false,
                          date: moment(),
                        })
                      }
                      value={0}
                      isChecked={isLaunchNow}
                      label={t("Launch a survey now")}
                      createSurvey={true}
                      disabled={createdSurveyDetails.status == "upcoming"}
                    />
                  </span>
                  <span>
                    <RadioButton
                      id="2"
                      handler={() =>
                        this.setState({
                          isSchedule: !isSchedule,
                          isLaunchNow: false,
                        })
                      }
                      value={1}
                      isChecked={isSchedule}
                      label={t("Schedule Survey for a Future Date")}
                      // challengeLeaderBoard={true}
                      createSurvey={true}
                      disabled={createdSurveyDetails.status == "active"}
                    />
                  </span>
                </div>

                {isSchedule || isLaunchNow ? (
                  <React.Fragment>
                    <StyledLabel style={{ color: "#005C87" }}>
                      Survey launch date
                    </StyledLabel>
                    <InputContainerV2 width={"47.5%"}>
                      <div
                        ref={(node) =>
                          openDateTimePicker === "date" &&
                          this.setDatePickerWrapper(node)
                        }
                      >
                        <StyledDateTime
                          open={openDateTimePicker === "date"}
                          inputProps={{
                            placeholder: "Select the launch date",
                            readOnly: true,
                            disabled: !isSchedule || isActiveOrUpcoming,
                          }}
                          dateFormat="MM/DD/YYYY"
                          closeOnSelect={true}
                          closeOnTab={true}
                          timeFormat={false}
                          value={
                            isLaunchNow
                              ? moment(date).format("MMMM DD,YYYY") +
                                " " +
                                "(Today's Date)"
                              : moment(date).format("MMMM DD,YYYY")
                          }
                          onChange={(e) => this.changeDate(e, "date")}
                          isValidDate={valid}
                          disabled={!isSchedule || isActiveOrUpcoming}
                          color={"#005C87"}
                          style={{
                            background: isActiveOrUpcoming
                              ? "rgba(0, 92, 135, 0.05)"
                              : "rgba(247, 245, 235, 0.50)",
                            border: "1px solid rgba(0, 92, 135, 0.30)",
                            color: isActiveOrUpcoming
                              ? "rgba(0, 92, 135, 0.6)"
                              : "#005C87",
                            fontFamily: "Rubik-Medium",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                          }}
                          // style={{color:"#005C87", fontFamily: "Rubik-Medium",fontSize: "16px",fontWeight: 500,lineHeight: "20px"}}
                        />
                        <DateIconContainer
                          onClick={() =>
                            isSchedule ||
                            (!isActiveOrUpcoming && this.showDatePicker("date"))
                          }
                          style={{
                            cursor:
                              isSchedule || !isActiveOrUpcoming
                                ? "default"
                                : "cursor",
                          }}
                        >
                          {isSchedule && calendarIcon("#005C87")}
                        </DateIconContainer>
                      </div>
                    </InputContainerV2>

                    <StyledLabel style={{ color: "#005C87" }}>
                      End date
                    </StyledLabel>

                    <div className="radioButtonDiv">
                      <span>
                        <RadioButton
                          id="3"
                          handler={() => this.setState({ endType: "date" })}
                          value={0}
                          isChecked={endType === "date"}
                          label={t("Close custom survey on:")}
                          createSurvey={true}
                          style={{ marginBottom: "0px" }}
                          disabled={isActiveOrUpcoming}
                        />

                        <InputContainerV2 width={"47.5%"}>
                          <div
                            ref={(node) =>
                              openDateTimePicker === "endDate" &&
                              this.setDatePickerWrapper(node)
                            }
                          >
                            <StyledDateTime
                              open={openDateTimePicker === "endDate"}
                              inputProps={{
                                placeholder: "Select the end date",
                                readOnly: true,
                                disabled:
                                  endType == "limit" || endType == "open",
                              }}
                              dateFormat="MM/DD/YYYY"
                              closeOnSelect={true}
                              closeOnTab={true}
                              timeFormat={false}
                              value={
                                endDate &&
                                moment(endDate).format("MMMM DD,YYYY")
                              }
                              onChange={(e) => this.changeDate(e, "endDate")}
                              isValidDate={validEndDta}
                              color={"#005C87"}
                              disabled={endType == "limit" || endType == "open"}
                            />
                            <DateIconContainer
                              onClick={() =>
                                endType == "date" &&
                                this.showDatePicker("endDate")
                              }
                              style={{
                                cursor:
                                  endType == "date" ? "pointer" : "default",
                              }}
                            >
                              {calendarIcon("#005C87")}
                            </DateIconContainer>
                          </div>
                        </InputContainerV2>
                      </span>
                    </div>

                    <div
                      className="radioButtonDiv"
                      style={{ marginTop: "15px" }}
                    >
                      <span style={{ marginRight: "0px" }}>
                        <RadioButton
                          id="4"
                          handler={() => this.setState({ endType: "limit" })}
                          value={0}
                          isChecked={endType === "limit"}
                          label={t(
                            "Close Custom Survey after participation reaches:"
                          )}
                          createSurvey={true}
                          disabled={isActiveOrUpcoming}
                        />

                        <div style={{ width: "170px" }}>
                          <StyledInputV2
                            placeholder={"100"}
                            name="title"
                            type="number"
                            value={this.state.participantLimit}
                            onChange={(e) =>
                              this.setState({
                                participantLimit: e.target.value,
                              })
                            }
                            // value={item.followUpQue}
                            // maxLength="50"
                            style={{
                              background:
                                endType == "date" || endType == "open"
                                  ? "rgba(0, 92, 135, 0.05)"
                                  : "rgba(247, 245, 235, 0.50)",
                              border: "1px solid rgba(0, 92, 135, 0.30)",
                              color:
                                endType == "date" || endType == "open"
                                  ? "rgba(0, 92, 135, 0.6)"
                                  : "#005C87",
                              fontFamily: "Rubik-Medium",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "20px",
                            }}
                            // onBlur={()=>this.onQueBlur(index, item, true)}
                            color={"#005C87"}
                            width={"170px"}
                            disabled={endType == "date" || endType == "open"}
                          />
                        </div>
                        <div
                          className="sub-title-style"
                          style={{ marginRight: "0px", marginLeft: "15px" }}
                        >
                          percent
                        </div>
                      </span>
                    </div>

                    <div
                      className="radioButtonDiv"
                      style={{ marginTop: "34px" }}
                    >
                      <span>
                        <RadioButton
                          id="5"
                          handler={() => this.setState({ endType: "open" })}
                          value={0}
                          isChecked={endType === "open"}
                          label={t("Do not close Custom Survey")}
                          createSurvey={true}
                          disabled={isActiveOrUpcoming}
                        />
                      </span>
                    </div>

                    <div className="open-survey">
                      <span>
                        This survey will continue to remain open to future
                        members who join your audience, until you archive it.
                      </span>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  handleShowCreateSurveyPopUP = () => {
    if (this.props.history?.location?.state?.type === "editsurveylibrary") {
      this.props.history.goBack();
    } else {
      if (this.state.isActiveOrUpcoming) {
        this.updateStep(3);
      } else {
        this.setState({ showCreateSurveyPopUp: true });
      }
    }
  };

  scrollToDiv = () => {
    if (this.targetDivRef.current) {
      this.targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
    this.addNewQue();
  };

  onDragStart = (event, index) => {
    event.dataTransfer.setData("dragIndex", index);
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event, dropIndex) => {
    const dragIndex = event.dataTransfer.getData("dragIndex");
    const { questionAnswers } = this.state;
    const items = [...questionAnswers];
    const draggedItem = items[dragIndex];

    // // Remove the item from the original position
    items.splice(dragIndex, 1);

    // // Insert the dragged item at the new position
    items.splice(dropIndex, 0, draggedItem);
    this.createSurveyQues(questionAnswers[dropIndex], Number(dragIndex),false,true);
    this.createSurveyQues(questionAnswers[dragIndex], Number(dropIndex),false,true);

    // questionAnswers = items;

    this.setState({ questionAnswers: items });
  };

  saveAsTemplate = async (suvreyId) => {
    // e.preventDefault();
    this.setState({ loading: true });
    const AuthToken = localStorage.getItem("AUTH_TOKEN");

    let url = BASE_URL + SaveSurveyAsTemplate + `/${suvreyId}`;

    await fetch(url, {
      method: "POST",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // body: JSON.stringify(surveyDetails),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              this.props.history.push("/company/surveys");
            } else {
              toast.error(data);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  areAllValuesNotEmpty(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].question == "") {
        this.setState({ disable: true });
        // setDisable(true)
      } else {
        // setDisable(false)
        this.setState({ disable: false });
      }
    }
  }

  validateQuestionAnswers = () => {

    let finalQuestionAnswers =[...this.state.questionAnswers];
    let isValid = true;
    let errors = [];
  
    finalQuestionAnswers?.forEach((item, index) => {
      const { question, answers } = item;
  
      // Validate question
      if (!question || question.trim() === "" || !item?.questionId) {
        isValid = false;
        errors.push(`Question at index ${index} is empty.`);
      }
  
      // Validate answers
      if(question?.questionType?.name != "short-answer"){
        answers?.forEach((answer, ansIndex) => {
          // console.log("answer + index", answer, "index",index);
          const { label, points, text } = answer;
    
          if (!label || label.trim() === "") {
            // console.log("if answer + index", answer, "index",index);
            isValid = false;
            errors.push(`Label is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
          }
          if (points.toString().trim() === "") {
            isValid = false;
            errors.push(`Points are empty in answer ${ansIndex + 1} of question ${index + 1}.`);
          }
          if (!text || text.trim() === "") {
            isValid = false;
            errors.push(`Text is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
          }
        });
      }
    });

    // console.log("isValid", isValid);
  
    // return { isValid, errors };
    return isValid;
  };

  validateSingleQuestionAnswers = (index) => {
    const { questionAnswers } = this.state;
    const errors = [];
    let isValid = true;
  
    const question = questionAnswers[index];
    if (!question) {
      errors.push(`Question at index ${index} does not exist.`);
      return { isValid: false, errors };
    }
  
    // Validate question field
    if (!question.question || question.question.trim() === "") {
      isValid = false;
      errors.push(`Question at index ${index + 1} is empty.`);
    }
  
    // Validate answers if question type is not "short-answer"
    if (question.questionType?.name !== "short-answer") {
      question.answers.forEach((answer, ansIndex) => {
        const { label, points, text } = answer;
  
        if (!label || label.trim() === "") {
          isValid = false;
          errors.push(`Label is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        }
        if (points.toString().trim() === "") {
          isValid = false;
          errors.push(`Points are empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        }
        if (!text || text.trim() === "") {
          isValid = false;
          errors.push(`Text is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        }
      });
    }
  
    // console.log("isValid", isValid);
    return isValid;
  };

  render() {
    const {
      step,
      title,
      imgSrc,
      description,
      // workOutVideoLink,
      // exerciseFocusAreas,
      loading,
      selectedSurveyCategory,
      // selectedSurveyPoint,
      showCreateSurveyPopUp,
      createdSurveyDetails,
      showQueReorderPopUp,
      questionAnswers,
      disable,
      endType,
      isLaunchNow,
      isSchedule,
      date,
      endDate,
      participantLimit,
    } = this.state;
    const { role } = this.props;
    if (loading) {
      return <Waiting />;
    }

    return (
      <React.Fragment>
        {step === 1
          ? this.stepOneForm()
          : step === 2
            ? this.stepTwoForm()
            : step === 3
              ? this.stepThreeForm()
              : this.stepTwoForm()}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <Button
                // marginAuto="1"
                disabled={
                  !title ||
                  !imgSrc ||
                  !description ||
                  !selectedSurveyCategory.id
                  // !selectedSurveyPoint.value
                }
                onClick={() => this.createSurvey()}
                color={"#005C87"}
              >
                {"Next >>"}
              </Button>
            </Main>
          </ButtonContainer>
        ) : step === 2 ? (
          <ButtonContainer>
            <div className="wrapper">
              <Button
                color={"white"}
                textColor={"#005C87"}
                border={"#005C87"}
                width={"113px"}
                marginLeft="1"
                onClick={() => this.updateStep(1)}
              >
                {"< Go Back"}
              </Button>
              <Button
                onClick={() => {
                  if (!disable) {
                    role === "ADMIN"
                      ? this.saveAsTemplate(
                        this.props.history.location.state.surveyId
                      )
                      : this.handleShowCreateSurveyPopUP();
                  }
                }}
                disabled={
                  (this.props.history.location.state &&
                    this.props.history.location.state.surveyId &&
                    !this.props.history.location.state.surveyId) ||
                  disable || !this.validateQuestionAnswers()
                }
                marginAuto="1"
                color={"#005C87"}
                style={{
                  // background: "#005C87",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                {/* {"Save"} */}
                {this.props.history.location &&
                this.props.history.location.state &&
                this.props.history?.location?.state?.type &&
                this.props.history?.location?.state?.type === "editsurveylibrary"
                  ? "Save"
                  : role === "ADMIN"
                    ? "Save As Template"
                    : "Next >>"}
              </Button>
            </div>
          </ButtonContainer>
        ) : step === 3 ? (
          <ButtonContainer>
            <div className="wrapper">
              <Button
                color={"white"}
                textColor={"#005C87"}
                border={"#005C87"}
                width={"113px"}
                marginLeft="1"
                onClick={() => this.updateStep(2)}
              >
                {"< Go Back"}
              </Button>
              <Button
                onClick={() => this.createSurvey(true)}
                disabled={
                  !this.props.history.location.state.surveyId ||
                  (!isLaunchNow && !isSchedule) ||
                  !date ||
                  (endType == "date" && !endDate) ||
                  (endType == "limit" && !participantLimit)
                }
                marginAuto="1"
                color={"#005C87"}
                style={{
                  // background: "#005C87",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                {/* {"Save"} */}
                {this.state.isActiveOrUpcoming
                  ? "Save The Update"
                  : "Launch Now"}
              </Button>
            </div>
          </ButtonContainer>
        ) : null}

        {showCreateSurveyPopUp && (
          <CreateSurveyPopUp
            headerText={"Survey Created"}
            showModal={showCreateSurveyPopUp}
            companyId={this.state.companyId}
            suvreyId={this.props.history.location.state.surveyId}
            updateStep={this.updateStep}
            onClose={() => {
              this.setState({ showCreateSurveyPopUp: false });
            }}
            title={createdSurveyDetails.title || ""}
          />
        )}
        {showQueReorderPopUp && (
          <QuestionReorderPopUp
            headerText={"Reorder Questions"}
            showModal={showQueReorderPopUp}
            companyId={this.state.companyId}
            suvreyId={this.props.history.location.state.surveyId}
            updateStep={this.updateStep}
            onClose={() => {
              this.setState({ showQueReorderPopUp: false });
            }}
            questions={questionAnswers}
            onDragStart={this.onDragStart}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
          />
        )}
      </React.Fragment>
    );
  }
}

CreateSurvey.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  t: PropTypes.func,
  companyInfo: PropTypes.object.isRequired,
};

export default withRouter(connect(null, null)(withTranslation()(CreateSurvey)));